import gql from 'graphql-tag'


export const LIST_CATEGORY = (templateType) => gql`query LIST_CATEGORY ($docType: String!, $q: FilterInput) {
  categories: list${templateType}Category (docType: $docType, q: $q) {
    id type code name
  }
}`

export const DETAIL_CATEGORY = (templateType) => gql`query DETAIL_CATEGORY ($docType: String!, $id: Int!) {
  category: detail${templateType}Category (docType: $docType, id: $id) {
    id type code name
  }
}`

export const CREATE_CATEGORY = (templateType) => gql`mutation CREATE_CATEGORY ($docType: String!, $input: DocCategoryInput!) {
  createCategory: create${templateType}Category (docType: $docType, input: $input) {
    id type code name
  }
}`

export const UPDATE_CATEGORY = (templateType) => gql`mutation UPDATE_CATEGORY ($docType: String!, $id: Int!, $input: DocCategoryInput!) {
  updateCategory: update${templateType}Category (docType: $docType, id: $id, input: $input) {
    id type code name
  }
}`

export const DESTROY_CATEGORY = (templateType) => gql`mutation DESTROY_CATEGORY ($docType: String!, $id: Int!) {
  destroyCategory: destroy${templateType}Category (docType: $docType, id: $id) {id}
}`

export const SYNC_CATEGORY = (templateType) => gql`mutation SYNC_CATEGORY ($docType: String!, $docId: Int!, $categories: [Int]!) {
  syncCategory: sync${templateType}Category (docType: $docType, docId: $docId, categories: $categories)
}`
